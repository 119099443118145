import { useRouteMatch, matchPath, useLocation } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { MESSAGE_TRANSCRIBING_SUBSCRIPTION } from "graphql-api";
import { useContext, useMemo } from "react";
import { FanPageContext } from "../context";

export const useMessageTranscribingSubscription = (showId) => {
  const { data, loading } = useSubscription(MESSAGE_TRANSCRIBING_SUBSCRIPTION, {
    variables: { showId },
  });
  return { data, loading };
};

export const useMenus = () => {
  const { show } = useContext(FanPageContext);
  const match = useRouteMatch();
  const { pathname } = useLocation();
  // Check inbox slug is active
  const isInboxSlugActive = !!matchPath(pathname, {
    path: `${match.path}/inbox/:inboxSlug`,
    exact: true,
    strict: false
  });
  const menus = useMemo(() => {
    const tabToMenu = {
      inbox: {
        title: 'Inbox',
        to: `${match.url}/inbox`.replace('//', '/'),
        path: `${match.path}/inbox`.replace('//', '/'),
        enabled: show.displayInbox,
        key: 'inbox',
        exact: true,
      },
      tips: {
        title: 'Tips',
        to: `${match.url}/tips`.replace('//', '/'),
        path: `${match.path}/tips`.replace('//', '/'),
        enabled: show.displayGeneralTips,
        key: 'tips',
        exact: true,
      },
      shop: {
        title: 'Shop',
        to: `${match.url}/shop`.replace('//', '/'),
        path: `${match.path}/shop`.replace('//', '/'),
        enabled: show.displayPerks,
        key: 'shop',
        exact: false,
      },
      podcast: {
        title: 'Podcast',
        to: `${match.url}/podcast`.replace('//', '/'),
        path: `${match.path}/podcast`.replace('//', '/'),
        enabled: show.displayMembers,
        key: 'podcast',
        exact: true,
      },
      sponsors: {
        title: 'Sponsors',
        to: `${match.url}/sponsors`.replace('//', '/'),
        path: `${match.path}/sponsors`.replace('//', '/'),
        enabled: show.displaySponsors,
        key: 'sponsors',
        exact: true,
      }
    };
    // defensive logic because database is advanced than code merge
    return show.tabsOnFanpage.filter(tab => tab in tabToMenu).filter(tab => tabToMenu[tab].enabled).map((tab, index) => {
      const menu = tabToMenu[tab];
      let to = menu.to;
      let path = menu.path;
      if (index === 0) {
        to = match.url;
        path = match.path;
      }
      if (tab === 'inbox' && isInboxSlugActive) {
        to = pathname;
        path = `${match.path}/inbox/:inboxSlug?`;
        if (index === 0) {
          path = `${match.path}/(inbox)?/:inboxSlug?`;
        }
      }
      return {
        ...menu,
        to,
        path
      }
    })
  }, [isInboxSlugActive, pathname, match, show]);
  return menus;
}