import React, { useMemo, useState, useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import { useMutation, useSubscription } from '@apollo/client';
import { DELETE_REPLY_MUTATION, UPDATE_REPLY_MUTATION, REPLY_TRANSCRIBED_SUBSCRIPTION } from 'graphql-api';
import { Menu, Typography } from "antd";
import { Avatar, Row, Col, message, Dropdown, MenuItem, A, Popover, Spin, OneCollapse } from 'atoms';
import { DeleteCta } from 'molecules';
import { TRUE } from 'consts';
import { FaEllipsisV, FaTrashAlt, FaPencilAlt } from 'icons';
import ReplyAudioPlayer from './ReplyAudioPlayer';
import ReplyTextContent from './ReplyTextContent';
import UserProfile from "../UserProfile";
import { CircleCheck } from 'icons';
import PaymentMessageControls from 'apps/PublicPage/components/PaymentMessageControls';
import MessageEditor from 'components/MessageEditor';
import { REPLY_QUERY } from 'apps/Dashboard/graphql/Message';

const { Paragraph } = Typography;
const S = {};

S.RepliedMessage = styled.div``;

const UserContainer = styled.div``;

const Title = styled.div`
  font-size: 16px;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.css}
`;

const Inner = styled.div`
  padding: 10px 15px;
  margin-top: 8px;
  margin-left: 3rem;

  ${(props) => props.css}
  .ant-typography-expand {
    color: var(--color-primary);

    &:hover {
      color: var(--color-primary-hover);
    }
  }
`;

const ControlMenu = styled(Menu)`
  min-width: 200px;
`;

const ContentContainer = styled.div`
  padding: 1em 15px 0;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  background-color: var(--color-body-background);
  margin-top: 5px;
  ${(props) => props.css}
`;

const TextControl = styled.a`
  font-size: 16px;
  cursor: pointer;
  color: var(--color-content-grey);
  margin-top: 4px;
  width: 29px;
  flex: 1 0 29px;
`;

const TextControlInner = styled(Inner)`
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;
  border: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  padding: 0 0px 0 5px;
`;

const Transcribing = styled.span`
  color: grey;
  margin-left: 15px;
`;

const SContentContainer = styled.div`
  padding: 1em 0 0 15px;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--color-body-background);
  ${(props) => props.css}
`;

const EditorContainer = styled.div`
  padding: 0 0 1em;
  width: 100%;
  flex: 1 0 100%;
  ${(props) => props.css}
`;

const SParagraph = styled(Paragraph)`
  flex: 1 0 calc(100% - 33px);
`;

const DeleteMenuItem = ({onDelete, loading, ...props}) => {
  return (
    <DeleteCta
      text={'Please confirm. This can not be undone'}
      onDelete={onDelete}
      loading={loading}
      showNo={false}
    >
      <MenuItem icon={<FaTrashAlt/>} {...props}>
        Delete
      </MenuItem>
    </DeleteCta>
  )
}

const [TEXT, AUDIO] = ['TEXT', 'AUDIO'];

const RepliedMessage = (
  {
    reply,
    control,
    theme: { isMobile },
    manualTranscribe,
    showTranscription,
    show,
    isLoggedIn,
    onTranscribe,
    ...props
  }
) => {
  const {text, transcodedAudioUrl, createdAt, duration, user, id, transcribedText} = reply;
  const replyType = useMemo(() => text ? TEXT : AUDIO, [text]);
  const [messageControlsVisible, setMessageControlsVisible] = useState(false);
  const [transcriptsEditorVisible, setTranscriptsEditorVisible] =
    useState(false);
  const isAdmin = useMemo(() => isLoggedIn && show.viewerIsAdmin, [show]);
  const isShowOwner = useMemo(() => isLoggedIn && show.viewerIsOwner, [show]);
  const [transcriptsControlsVisible, setTranscriptsControlsVisible] =
    useState(false);

  const [triggerDeleteReply, {loading: isDeleteBusy}] = useMutation(DELETE_REPLY_MUTATION, {
    variables: {
      replyId: id
    },
    onCompleted({deleteReply: {success, error}}) {
      if (success === TRUE) {
        message.success({content: 'Message deleted'});
      } else {
        message.error({content: 'Something went wrong, please try again later'});
      }
    }
  });

  const [triggerUpdateReply, { loading: isUpdateBusy }] = useMutation(UPDATE_REPLY_MUTATION, {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: REPLY_QUERY, variables: { id: reply.id } }],
      onCompleted({ updateReply: { success } }) {
        if (success === TRUE) {
          setTranscriptsEditorVisible(false);

          message.success({ content: "Changes Saved" });
        } else {
          message.error({
            content: "Something went wrong, please try again later.",
          });
        }
      },
      onError() {
        message.error({
          content: "Something went wrong, please try again later.",
        });
      },
    }
  );

  const handleDelete = () => {
    triggerDeleteReply();
  }

  const handleMessageControlsOpenChange = (e) => {
    setMessageControlsVisible(e);
  };

  const handleTranscribe = () => {
    return onTranscribe && onTranscribe();
  };

  const handleTranscriptsControlsOpenChange = (e) => {
    setTranscriptsControlsVisible(e);
  };

  const handleTranscriptsToggle = () => {
    setTranscriptsEditorVisible(!transcriptsEditorVisible);

    setTranscriptsControlsVisible(false);
  };

  const handleUpdateReplyContent = async (payload) => {
    await triggerUpdateReply({
      variables: { replyId: reply.id, ...payload },
    });
  };

  useSubscription(REPLY_TRANSCRIBED_SUBSCRIPTION, {
    variables: { replyId: id },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.replyTranscribed?.transcribedText) {
        message.success({ content: 'Transcription updated' });
        reply.transcribedText = data.replyTranscribed.transcribedText;
      }
    },
  });

  return (
    <S.RepliedMessage>
      <UserContainer>
        <Row singleRow gutter={isMobile ? 10 : 12}>
          <Col minZero>
            <Avatar 
              css={`border-radius: 5px;`} 
              circle={false} 
              src={user.avatarUrl}
              width={isMobile ? "40px" : "50px"}
            />
          </Col>
          <Col flex={'1 0 0'}>
            <Row
              align={'middle'}
              justify={'space-between'}
              gutter={16}
              css={control ? `padding-right: 7px;` : ``}
            >
              <Col minZero>
                <Popover
                  placement={"top"}
                  trigger={["click"]}
                  content={<UserProfile user={user}/>}
                  simple
                >
                  <A>
                    <Title css={isMobile ? `font-size: 14px;` : ``}>
                      {user.displayName}{" "}
                      {user.isMembership && <CircleCheck color="#0092ff"/>}
                    </Title>
                  </A>
                </Popover>
              </Col>
            </Row>

            <ContentContainer
              css={replyType === AUDIO ? `padding: 0;
                background-color: transparent;
                margin-top: 10px;
                border: 0;` : ``}
            >
              {
                replyType === TEXT && (
                  <>
                    {transcriptsEditorVisible ? (
                      <EditorContainer>
                        <OneCollapse open={transcriptsEditorVisible}>
                          <MessageEditor
                            onCancel={handleTranscriptsToggle}
                            onUpdate={handleUpdateReplyContent}
                            message={reply.text}
                            loading={isUpdateBusy}
                            text={"edit"}
                            required
                          />
                        </OneCollapse>
                      </EditorContainer>
                    ) : (
                      <>
                        <ReplyTextContent text={text} />
                        {isLoggedIn && (!!isShowOwner || !!isAdmin) && (
                          <Dropdown
                            trigger={["click"]}
                            open={messageControlsVisible}
                            arrow
                            onOpenChange={handleMessageControlsOpenChange}
                            key={'DELETE'}
                            overlay={
                              <ControlMenu>
                                {replyType === "TEXT" && reply.text && (
                                  <MenuItem onClick={handleTranscriptsToggle}>
                                    <FaPencilAlt css={`
                                      margin-inline-end: 8px;
                                      font-size: 12px;`
                                    } />Edit Reply
                                  </MenuItem>
                                )}
                                <DeleteMenuItem
                                  key={'DeleteReply'}
                                  onDelete={handleDelete}
                                  loading={isDeleteBusy}
                                />
                              </ControlMenu>
                            }
                          >
                            <TextControl>
                              <TextControlInner>
                                <FaEllipsisV />
                              </TextControlInner>
                            </TextControl>
                          </Dropdown>
                        )}
                      </>
                    )}
                  </>
                )
              }
              {
                replyType === AUDIO && (
                  <ReplyAudioPlayer src={transcodedAudioUrl} duration={duration}/>
                )
              }
            </ContentContainer>
          </Col>
        </Row>

        {replyType === "AUDIO" &&
          showTranscription &&
          !reply.transcribedText &&
          manualTranscribe && (
            <Inner
              css={`
                margin-top: -2px;
                padding-right: 0;
              `}
            >
              <SContentContainer
                css={`
                  padding-bottom: 1em;
                  justify-content: start;
                `}
              >
                {reply.transcribing || reply.transcribingIntended ? (
                  <>
                    <Spin /> <Transcribing>transcribing</Transcribing>
                  </>
                ) : (
                  <A onClick={handleTranscribe}>transcribe</A>
                )}
              </SContentContainer>
            </Inner>
        )}
        {replyType === "AUDIO" && showTranscription && transcribedText && (
          <Inner
            css={`
              margin-top: -2px;
              padding-right: 0;
              ${isMobile ? 'padding-left: 2px;' : ''}
            `}
          >
            <SContentContainer
              css={
                transcriptsEditorVisible ||
                  !isLoggedIn ||
                  !(!!isShowOwner || !!isAdmin)
                  ? `padding-right: 15px;`
                  : ``
              }
            >
              {transcriptsEditorVisible ? (
                <EditorContainer>
                  <OneCollapse open={transcriptsEditorVisible}>
                    <MessageEditor
                      onCancel={handleTranscriptsToggle}
                      onUpdate={handleUpdateReplyContent}
                      message={transcribedText}
                      loading={isUpdateBusy}
                      text={"edit"}
                      required
                    />
                  </OneCollapse>
                </EditorContainer>
              ) : (
                <>
                  <SParagraph
                    ellipsis={{ rows: 3, expandable: true, symbol: "more" }}
                  >
                    {reply.transcribedText}
                  </SParagraph>
                  {isLoggedIn && (isShowOwner || isAdmin) && (
                      <Dropdown
                        trigger={["click"]}
                        open={messageControlsVisible}
                        arrow
                        onOpenChange={handleMessageControlsOpenChange}
                        key={'DELETE'}
                        overlay={
                          <ControlMenu>
                            {replyType === "AUDIO" && showTranscription && transcribedText && (
                              <MenuItem onClick={handleTranscriptsToggle}>
                                <FaPencilAlt css={`
                                    margin-inline-end: 8px;
                                    font-size: 12px;`
                                } />Edit Transcript
                              </MenuItem>
                            )}
                            <DeleteMenuItem
                              key={'DeleteReply'}
                              onDelete={handleDelete}
                              loading={isDeleteBusy}
                            />
                          </ControlMenu>
                        }
                      >
                        <TextControl>
                          <TextControlInner>
                            <FaEllipsisV/>
                          </TextControlInner>
                        </TextControl>
                      </Dropdown>
                    )
                  }
                </>
              )}
            </SContentContainer>
          </Inner>
        )}
      </UserContainer>
    </S.RepliedMessage>
  )
}

RepliedMessage.defaultProps = {
  manualTranscribe: true,
  showTranscription: true,
};

export default withTheme(RepliedMessage);
